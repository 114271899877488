import Plyr from 'plyr';
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import createFooterNewsletterForm from '@/components/forms/newsletter';
import createContactForm from '@/components/forms/contact';
import createDownloadForms from '@/components/forms/download';
import createLegalForm from '@/components/forms/legal';
import createCTAForms from '@/components/forms/cta';
import createTrialForms from '@/components/forms/trial';
import createContactWithTelephoneForms from '@/components/forms/contact_with_telephone';
import createROIForms from '@/components/forms/roi';
import createInTouchForms from '@/components/forms/intouch';

import '@/helpers/datadog';

// eslint-disable-next-line no-unused-vars
import { Collapse, Dropdown } from 'bootstrap';

function setupPlyr() {
  // HTML5, Youtube and Vimeo player
  // eslint-disable-next-line no-unused-vars
  const players = Plyr.setup('.js-video', {
    iconUrl: 'https://static.getperacdn.com/static/svg/plyr.svg',
  });

  const playersFullscreen = Plyr.setup('.js-video-fullscreen', {
    iconUrl: 'https://static.getperacdn.com/static/svg/plyr.svg',
  });

  if (playersFullscreen && playersFullscreen.length > 0) {
    playersFullscreen.forEach(playerFullscreen => {
      const player = playerFullscreen.media.parentNode.parentNode
      const button = player.parentNode.querySelector('.video-fullscreen__button');

      button.addEventListener('click', () => {
        playerFullscreen.play();
        player.classList.add('played');
      });
    });
  }
}


function setupSliders() {
  // Swiper sliders
  document
    .querySelectorAll('.swiper.featured-slider__slider')
    .forEach(function (element, index) {
      const instanceClass = 'instance-' + index;
      element.classList.add(instanceClass);

      const prevButton = element.querySelector('.featured-slider__prev');
      const nextButton = element.querySelector('.featured-slider__next');

      prevButton.classList.add('btn-prev-' + index);
      nextButton.classList.add('btn-next-' + index);

      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper('.' + instanceClass, {
        modules: [Navigation],
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.btn-next-' + index,
          prevEl: '.btn-prev-' + index,
        },
      });
    });

  document
    .querySelectorAll('.swiper.logo-slider')
    .forEach(function (element, index) {
      const instanceClass = 'logo-slider-' + index;
      element.classList.add(instanceClass);

      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper('.' + instanceClass, {
        modules: [Autoplay],
        slidesPerView: 'auto',
        autoplay: {
          delay: 2000,
        },
      });
    });

  document
    .querySelectorAll('.swiper.numbers-slider')
    .forEach(function (element, index) {
      const instanceClass = 'number-slider-' + index;
      element.classList.add(instanceClass);

      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper('.' + instanceClass, {
        modules: [Autoplay],
        slidesPerView: 'auto',
        autoplay: {
          delay: 2000,
        },
      });
    });
}

function setupProductTour() {
  document.querySelectorAll('.product-tour').forEach((tour) => {
    const links = tour.querySelectorAll('.product-tour__link');
    const features = tour.querySelectorAll('.product-tour__feature');

    // Activate the first link and feature on load
    if (links[0] && features[0]) {
      links[0].classList.add('active');
      features[0].classList.add('active');
    }

    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        // Deactivate all links and features
        links.forEach((l) => l.classList.remove('active'));
        features.forEach((f) => f.classList.remove('active'));

        // Activate the clicked link and corresponding feature
        link.classList.add('active');
        const featureToActivate = tour.querySelector(`.product-tour__feature[data-feature="${link.dataset.feature}"]`);
        featureToActivate?.classList.add('active'); // Optional chaining for safety
      });
    });
  });
}

// Stackable logic
function setStackableClasses() {
  const processStackables = (selector, isFirst, isLast) => {
    const stackables = document.querySelectorAll(selector);
    let firstFound = false;

    stackables.forEach((stackable, index) => {
      if (!firstFound) {
        stackable.classList.add(isFirst);
        firstFound = true;
      }

      const nextElement = stackable.nextElementSibling;

      if (!nextElement || !nextElement.matches(selector)) {
        stackable.classList.add(isLast);
        firstFound = false; // Reset for potential next sequence
      }
    });
  };

  // Process elements with 'block--default' and 'stackable' classes
  processStackables(
    '.block--default.stackable',
    'stackable--first',
    'stackable--last'
  );

  // Process elements with 'block--light' and 'stackable' classes
  processStackables(
    '.block--light.stackable',
    'stackable--first',
    'stackable--last'
  );

  // Process elements with 'block--default' and 'faq-category' classes
  processStackables(
    '.block--default.faq-category',
    'faq-category--first',
    'faq-category--last'
  );

  // Process elements with 'block--light' and 'faq-category' classes
  processStackables(
    '.block--light.faq-category',
    'faq-category--first',
    'faq-category--last'
  );
}

// Header scroll
function addScrolledClassOnScroll() {
  const navbar = document.querySelector('.navbar');

  window.addEventListener('scroll', function () {
    if (window.scrollY > 0) {
      navbar.classList.add('navbar--scrolled');
    } else {
      navbar.classList.remove('navbar--scrolled');
    }
  });
}

function setupAccordions() {
  // Accordions
  const accordions = document.getElementsByClassName('accordion');

  // Loop through each accordion element
  for (var i = 0; i < accordions.length; i++) {
    accordions[i].addEventListener('click', function () {
      // Toggle the active class on the clicked accordion
      this.classList.toggle('active');
    });
  }
}

function setupForms() {
  createFooterNewsletterForm();
  createContactForm();
  createDownloadForms();
  createLegalForm();
  createCTAForms();
  createTrialForms();
  createContactWithTelephoneForms();
  createInTouchForms();
  createROIForms();
}

function showCookieBanner(){
  const el = document.getElementById('wtm_cookie_bar');
  if(el){
    el.classList.remove('hidden');
  }
}

function setupCookieBanner(){
  const els = document.getElementsByClassName('wtm-show-button');
  if (els.length === 0){
    return;
  }
  for (const e of els) {
    e.addEventListener('click', () => {
      showCookieBanner();
    });
  };
}

// DOM Content Loaded
window.addEventListener(
  'DOMContentLoaded',
  function () {
    setupPlyr();
    setupSliders();
    setupProductTour();
    setStackableClasses();
    addScrolledClassOnScroll();
    setupAccordions();
    setupForms();
    setupCookieBanner();
  },
  false
);
