export function missing_hubspot_cookie(data) {
  if ('non_field_errors' in data) {
    if (
      data['non_field_errors'].includes(
        'api.validation.data.missing_hubspot_cookie'
      )
    ) {
      return true;
    }
  }
  return false;
}

export function not_company_email(data) {
  if ('email' in data) {
    if (data['email'].includes('api.validation.data.not_a_company_email')) {
      return true;
    }
  }
  return false;
}

export function invalid_phone_number(data) {
  if ('phone_number' in data) {
    if (
      data['phone_number'].includes('api.validation.data.invalid_phone_number')
    ) {
      return true;
    }
  }
  return false;
}

export function track_form_submission(w, name, destination){
  /*
    LinkedIn
    https://business.linkedin.com/marketing-solutions/insight-tag
    https://www.linkedin.com/help/lms/answer/a425606/set-up-linkedin-conversion-tracking?lang=en
    https://www.linkedin.com/help/lms/answer/a1655394
    https://github.com/linkedin-developers/linkedin-api-js-client
   */
  if (w.lintrk) {
    w.lintrk('track', { conversion_id: 11943524 });
  }
  /*
    Google Analytics
    https://support.google.com/analytics/answer/9216061?hl=en
    https://support.google.com/analytics/answer/9234069?hl=en
    https://developers.google.com/tag-platform/gtagjs/reference#event
   */
  if (w.gtag) {
    w.gtag('event', 'form_submit', {
      'form_name': name,
      'form_destination': destination,
    });
  }
}

export function redirect_after(url){
  function make_redirector(inurl) {
    return () => (window.location = inurl);
  }
  setTimeout(make_redirector(url), 2000);
}
