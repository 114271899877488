export function lookup(lang, mid) {
  const langs = {
    en: {
      agree_privacy:
        'I agree to the <a href="/en/legal/privacy/">privacy policy</a> and <a href="/en/legal/terms/">terms of service</a>',
      company_email: 'Company email address',
      company_name: 'Company name',
      email_address: 'Email address',
      error: 'There was an error.',
      first_name: 'First name',
      help: 'Help',
      hs_cookies: 'You must enable HubSpot cookies for this form to work.',
      invalid_phone: 'Please enter a valid phone number',
      job_title: 'Job title',
      last_name: 'Last name',
      // eslint-disable-next-line @stylistic/js/quotes
      legal_para: "We'll send you an email if any of our policies are changed.",
      legal_title: 'Subscribe to updates',
      no_redirect: 'Click here if not redirected',
      thanks_redirect: 'Thank you redirecting...',
      please_wait: 'Please wait...',
      phone_number: 'Phone number',
      send: 'Send',
      subs_thanks: 'Thanks for subscribing!',
      subs_title: 'Subscribe to our newsletter',
      thanks_for_contacting: 'Thanks for contacting us!',
      thanks_for_downloading: 'Thanks for downloading!',
      use_company_email: 'You must use your company email address.',
      your_message: 'Your message',
      roi_ave: 'Average annual revenue per sales person',
      roi_top: 'Annual revenue for a top performing sales person',
      roi_num: 'Number of sales people hired per year',
      roi_ave_top:
        'Top performers revenue must be greater than average performers',
    },
    nl: {
      agree_privacy:
        'Ik ga akkoord met het <a href="/en/legal/privacy/">privacybeleid</a> en de <a href="/en/legal/terms/">servicevoorwaarden</a>',
      company_email: 'Zakelijk e-mailadres',
      company_name: 'Bedrijfsnaam',
      email_address: 'E-mailadres',
      error: 'Er is een fout opgetreden.',
      first_name: 'Voornaam',
      help: 'Hulp',
      hs_cookies:
        'U moet HubSpot-cookies inschakelen om dit formulier te laten werken.',
      invalid_phone: 'Voer alstublieft een geldig telefoonnummer in',
      job_title: 'Functietitel',
      last_name: 'Achternaam',
      legal_para:
        'We sturen u een e-mail als een van ons beleid wordt gewijzigd.',
      legal_title: 'Abonneer u op updates',
      no_redirect: 'Klik hier als u niet wordt doorgestuurd',
      thanks_redirect: 'Thank you redirecting...',
      please_wait: 'Even geduld aub...',
      phone_number: 'Telefoonnummer',
      send: 'Versturen',
      subs_thanks: 'Bedankt voor het abonneren!',
      subs_title: 'Abonneer op onze nieuwsbrief',
      thanks_for_contacting: 'Bedankt voor uw bericht!',
      thanks_for_downloading: 'Bedankt voor het downloaden!',
      use_company_email: 'U moet een zakelijk e-mailadres gebruiken.',
      your_message: 'Jouw bericht',
      roi_ave: 'Average annual revenue per sales person',
      roi_top: 'Annual revenue for a top performing sales person',
      roi_num: 'Number of sales people hired per year',
      roi_ave_top:
        'Top performers revenue must be greater than average performers',
    },
  };
  const default_lang = 'en';
  let index_lang = lang;
  if (!(lang in langs)) {
    index_lang = default_lang;
  }
  const active_lang = langs[index_lang];
  if (mid in active_lang) {
    return active_lang[mid];
  }
  return mid;
}
